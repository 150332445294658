<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>الصرف الصحي</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row
          ><h2 class="mt-10">
            تعريف بأهمية التخلص الآمن من الصرف الصحى
          </h2></v-row
        >
        <v-row>
          <v-col cols="12" lg="8" sm="10" xs="10">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel
                v-for="(item, i) in ArrayOFAnElsherka"
                :key="i"
                class="changeTitleStyle"
              >
                <v-expansion-panel-title class="fade-in">
                  <h3>{{ item.title }}</h3>
                </v-expansion-panel-title>
                <v-expansion-panel-content>
                  <h4 class="importantSite" v-if="item.text">
                    {{ item.text }}
                  </h4>
                  <h4 v-if="item.text2">
                    {{ item.text2 }}
                  </h4>
                  <ul v-if="item.subtext">
                    <li v-for="i in item.subtext" :key="i">
                      <h3 v-animate-onscroll="{ down: 'text-shadow-pop-top' }">
                        {{ i.subTitle }}
                      </h3>
                      <h4>{{ i.subtext }}</h4>
                      <br />
                      <h4>{{ i.subtext2 }}</h4>
                      <br />
                    </li>
                  </ul>
                  <h4 class="brown--text" v-if="item.text3">
                    {{ item.text3 }}
                  </h4>
                  <h4 v-if="item.text4">{{ item.text4 }}</h4>
                  <br />
                  <h4 v-if="item.text5">{{ item.text5 }}</h4>
                  <br />
                  <h4 v-if="item.text6">{{ item.text6 }}</h4>
                  <br />
                  <h4 v-if="item.text7">{{ item.text7 }}</h4>
                  <br />
                  <h4 v-if="item.text8">{{ item.text8 }}</h4>
                  <br />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" lg="4" sm="4" xs="4">
            <Link></Link>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  components: { Link },
  data() {
    return {
      panel: [0],
      ArrayOFAnElsherka: [
        {
          title: "نبذه عن أهميه التخلص مياه الصرف الصحي ",
          text2:
            " التخلص من میاه الصرف الصحي ھو التصریف الآمن والمستدام بیئیا لإعادة استخدام میاه الصرف المعالجة، وتتولى الشركة القابضة لمیاه الشرب والصرف الصحى والشركات التابعھ ومنھا شركة اسیوط والوادى الجدید لمیاه الشرب والصرف الصحى بمعظم عملیات التخلص من میاه الصرف الصحي المعاد تدویرھا والمواد الصلبة الناتجھ ویطلق على أیة میاه یمكن إعادة استخدامھا مرة أخرى المیاه المعاد تدویرھا والحمأة الناتجة عن میاه الصرف الصحي المعالجة المخلفات الصلبة بالحمأه ",
          text4:
            "یتم استخدام المیاه المعاد تدویرھا لري المسطحات الخضراء والحدائق وتعتبر جمھوریة مصر العربیھ من أوائل الدول التي طبقت الإرشادات التوجیھیة لمنظمة الصحة العالمیة بشأن إعادة استخدام المیاه.",
          text5:
            "إن استخدام المیاه المعاد تدویرھا یلعب دورا ھاما في تلبیة الطلب المتزاید على المیاه غیر الصالحة للشرب في مجالات الري للغابات الشجریھ والتبرید والمطالب الصناعیة.",
          text6:
            " تحتوي المخلفات الصلبة (الحمأه ) على عناصر غذائیة وعضویة مھمة ومن الممكن استخدامھا كأسمد أو كمحسنات للتربة أو كوقود لبعض المصانع وتقوم الدولھ حالیا بدراسة مزید من الخیارات للاستفادة من تلك المخلفات",
        },
      ],
    };
  },
};
</script>
<style>
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
</style>
